import React, { Component } from 'react';
import { StatePlugin } from '../../capacitor-connector';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import * as Shapes from '../../shapes';

import CapacitorRipple from '../capacitor/Ripple';
import LivestreamEventRelativeTime from './LivestreamEventRelativeTime';
import CurrentlyLive from './CurrentlyLive';
import EasyConnectButton from './EasyConnectButton';
import AvailabilityInfo from '../livestream-event-preview/AvailabilityInfo';
import CoursePeriod from '../livestream-event-preview/CoursePeriod';
import EventPrice from '../livestream-event-preview/Price';
import Price from '../common/Price';
import Tags from '../recording/RecordingTags';
import PriceInfoTooltip from '../livestream-event/PriceInfoTooltip';

import livestreamEventComponent from '../../hoc/livestreamEventComponent';

import styles from './LivestreamEventHero.css';
import { PLAN_ID_PPC_12M, SUBSCRIPTION_PLANS } from '../../constants';
import ReactTooltip from 'react-tooltip';
import AccountJoin from '../messages/AccountJoin';

class LivestreamEventHero extends Component {
  static propTypes = {
    event: Shapes.LivestreamEvent,
    renderHeroMedia: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onOrderComplete: PropTypes.func.isRequired,
    onPurchaseClick: PropTypes.func.isRequired,
    onSubscribeClick: PropTypes.func.isRequired,
    onSignupClick: PropTypes.func.isRequired,

    userHasTicket: PropTypes.bool.isRequired,
    userCanPlaybackEvent: PropTypes.bool.isRequired,
    userCanAccessSubscribeView: PropTypes.bool.isRequired,

    ticketPurchaseIsDisabled: PropTypes.bool.isRequired,
    shouldShowPrice: PropTypes.bool.isRequired,

    isAuth: PropTypes.bool.isRequired,
  };

  state = {
    capacitorTicketPurchaseIsEnabled: null,
  };

  async componentDidMount() {
    if (__CAPACITOR__) {
      const webTicketPurchaseState = await StatePlugin.androidShouldSendCloudConfigItem({
        cloudConfigKey: 'WebTicketPurchaseEnabled',
      });

      if (
        webTicketPurchaseState &&
        typeof webTicketPurchaseState.cloudConfigItemValue === 'string'
      ) {
        // We want to make sure that if the toggle is not set or set to a
        // non-boolean value, we default to true
        const isWebTicketPurchaseEnabled = webTicketPurchaseState.cloudConfigItemValue !== 'false';
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          capacitorTicketPurchaseIsEnabled: isWebTicketPurchaseEnabled,
        });
      } else {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          capacitorTicketPurchaseIsEnabled: true,
        });
      }
    }
  }

  onClick = () => {
    const { onClick, event } = this.props;
    if (onClick) {
      onClick(event);
    }
  };

  renderDefaultTime() {
    const { event } = this.props;

    return (
      <React.Fragment>
        {event.isLive ? (
          <CurrentlyLive />
        ) : (
          <div className="live-timestamp">
            <LivestreamEventRelativeTime event={event} />
          </div>
        )}
      </React.Fragment>
    );
  }

  renderTime() {
    const { event } = this.props;

    if (event.isConcert) {
      return <AvailabilityInfo event={event} showUpcomingTime />;
    }

    if (event.isCourse) {
      return <CoursePeriod event={event} />;
    }

    return this.renderDefaultTime();
  }

  renderPrice = () => {
    const { price } = this.props.event;
    const { isFree, isFixed } = price;

    return (
      <div className={styles.price} data-test="livestream-event-hero.price">
        <EventPrice price={price} />
        {!isFree && !isFixed && <PriceInfoTooltip />}
      </div>
    );
  };

  renderFreeEventsCTA = () => {
    const { onSignupClick, isAuth } = this.props;

    const id = 'create-account';
    return (
      <React.Fragment>
        {!isAuth && (
          <span>
            <button
              className={`c-btn c-btn--is-purple ${styles.ctaButton}`}
              data-tip
              data-for={id}
              onClick={onSignupClick}
              data-test="livestream-event-hero.create-account-btn"
            >
              <CapacitorRipple />
              <AccountJoin />
            </button>
            <ReactTooltip className={styles.tooltip} id={id} effect="solid" place="top">
              <FormattedMessage
                id="livestream-events.create-account-tooltip.text"
                defaultMessage="This event is free. Simply log in or create your free IDAGIO account to watch."
              />
            </ReactTooltip>
          </span>
        )}
        {this.renderSubscribeCTA(isAuth ? 'c-btn--is-purple' : '')}
      </React.Fragment>
    );
  };

  renderTicketPurchaseDisabled = () => {
    return (
      <div>
        <p className={styles.info}>
          <FormattedMessage
            id="livestream-events.featured-event.exclusive-to-p+c-subscribers-text"
            defaultMessage="Exclusive to Premium+ Concerts subscribers"
          />
        </p>
        {this.renderSubscribeCTA('c-btn--is-purple')}
      </div>
    );
  };

  renderSubscribeCTA = className => {
    const { onSubscribeClick, userCanAccessSubscribeView } = this.props;

    if (!userCanAccessSubscribeView) {
      return null;
    }

    const id = 'access-all-concerts';

    return (
      <span>
        <button
          className={`c-btn ${styles.ctaButton} ${className || ''}`}
          data-tip
          data-for={id}
          onClick={onSubscribeClick}
          data-test="livestream-event-hero.access-all-concerts-btn"
        >
          <CapacitorRipple />
          <FormattedMessage
            id="livestream-events.cta.access-all-concerts"
            defaultMessage="Access all concerts"
          />
        </button>
        <ReactTooltip className={styles.tooltip} id={id} effect="solid" place="top">
          <FormattedMessage
            id="livestream-events.access-all-concerts-tooltip.text"
            defaultMessage="Get unlimited access to all concerts for just {price}/month"
            values={{
              price: <Price price={SUBSCRIPTION_PLANS[PLAN_ID_PPC_12M].monthlyPrice} />,
            }}
          />
        </ReactTooltip>
      </span>
    );
  };

  renderPurchaseCTA = () => {
    const { isAuth, event, onOrderComplete, onPurchaseClick, ticketPurchaseIsDisabled } =
      this.props;

    if (__CAPACITOR__ && this.state.capacitorTicketPurchaseIsEnabled === null) {
      // We're loading
      return null;
    }

    const shouldDisableTicketPurchase =
      ticketPurchaseIsDisabled || (__CAPACITOR__ && !this.state.capacitorTicketPurchaseIsEnabled);

    if (shouldDisableTicketPurchase && event.isIncludedInConcertTier) {
      return this.renderTicketPurchaseDisabled();
    }

    return (
      <React.Fragment>
        <EasyConnectButton
          eventId={event.id}
          eventSlug={event.slug}
          eventType={event.type}
          onClick={onPurchaseClick}
          onOrderComplete={onOrderComplete}
          className={styles.ctaButton}
          isAuth={isAuth}
          isCourse={event.isCourse}
        />
        {event.isIncludedInConcertTier && this.renderSubscribeCTA('c-btn--is-purple')}
      </React.Fragment>
    );
  };

  renderIsBooked = () => {
    return (
      <div>
        <div className={styles.isBooked} data-test="livestream-event-hero.booked-tag">
          <FormattedMessage
            id="livestream-events.featured-event.event-booked"
            defaultMessage="Booked"
          />
        </div>
        {this.props.event.isUpcoming && (
          <p className={styles.info} data-test="livestream-event-hero.booked-info">
            <FormattedMessage
              id="livestream-events.featured-event.event-booked-info"
              defaultMessage="Please return to this page at the start of the event"
            />
          </p>
        )}
      </div>
    );
  };

  renderTicketsCTA = () => {
    const { userHasTicket } = this.props;
    return userHasTicket ? this.renderIsBooked() : this.renderPurchaseCTA();
  };

  render() {
    const { event, renderHeroMedia, shouldShowPrice, userCanPlaybackEvent } = this.props;

    return (
      <React.Fragment>
        {__CAPACITOR__ && <h2>{event.title}</h2>}
        {renderHeroMedia()}

        <div className={styles.ctasWrapper}>
          {!event.isBookable && this.renderFreeEventsCTA()}
          {event.areTicketsAvailable && !userCanPlaybackEvent && this.renderTicketsCTA()}
        </div>

        <div className={styles.eventInfo}>
          <div className={styles.time}>{this.renderTime()}</div>
          {shouldShowPrice && this.renderPrice()}
          {event.tags && <Tags tags={event.tags} />}
        </div>
      </React.Fragment>
    );
  }
}

export default livestreamEventComponent()(LivestreamEventHero);
