import PropTypes from 'prop-types';
import { debounce, sum } from 'lodash';
import React, { PureComponent } from 'react';

import { shareableEntityPropTypes } from '../../hoc/shareableEntity';
import { collectibleEntityPropTypes } from '../../hoc/collectibleEntity';
import { queueOriginComponentPropTypes } from '../../hoc/queueOriginComponent';
import { playlistableEntityPropTypes } from '../../hoc/playlistableEntity';
import {
  List as VirtualizedList,
  AutoSizer,
  WindowScroller,
  CellMeasurerCache,
  CellMeasurer,
} from 'react-virtualized';

import classnames from 'classnames';
import { messages as radioButtonMessages } from './PlayRadioButton';
import { defineMessages, FormattedDate, FormattedMessage, intlShape } from 'react-intl';
import { Link } from 'react-router';

import * as Shapes from '../../shapes';

import PlaylistItem from '../playlist/PlaylistItem';
import PlayButton from './PlayButton';

import CollectionButton from './CollectionButton';
import ImageCredit from '../common/ImageCredit';
import isDuplicatePiece from '../../utils/isDuplicatePiece';
import ShareButton from '../common/ShareButton';
import BookletButton from '../common/BookletButton';
import CapacitorHeaderBar from '../capacitor/HeaderBar';
import CapacitorDownloadButton from '../capacitor/DownloadButton';
import CapacitorSkeletonIfLoading from '../capacitor/SkeletonIfLoading';

import styles from './GenericTracklistView.css';
import { Image } from '../util/Image';

import AddToPlaylistButton from './AddToPlaylistButton';
import ContextMenu from '../util/ContextMenu';
import ContextMenuItem from '../util/ContextMenuItem';
import PlaylistTrackCount from '../messages/PlaylistTrackCount';
import FormattedTime from '../util/FormattedTime';
import Feature from '../util/Feature';
import { editableEntityPropTypes } from '../../hoc/editableEntity';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../../utils/array';
import { ENTITY_TYPE_MIX } from '../../constants';
import IconLabel from '../util/IconLabel';
import AddToPlaylistContextMenuItem from './AddToPlaylistContextMenuItem';
import ShareContextMenuItem from './ShareContextMenuItem';

const messages = defineMessages({
  sharePlaylist: {
    id: 'playlist.share',
    defaultMessage: 'Share playlist',
  },
  shareAlbum: {
    id: 'album.share',
    defaultMessage: 'Share album',
  },
});

const EditContextMenuItems = ({ onEdit, onDelete }) => (
  <React.Fragment>
    <ContextMenuItem onClick={onEdit}>
      <IconLabel name="rename" size="default" />
      <FormattedMessage
        id="generic-track-list.editable.context-menu.rename"
        defaultMessage="Rename"
      />
    </ContextMenuItem>
    <ContextMenuItem onClick={onDelete}>
      <IconLabel name="delete" size="default" />
      <FormattedMessage
        id="generic-track-list.editable.context-menu.delete"
        defaultMessage="Delete"
      />
    </ContextMenuItem>
  </React.Fragment>
);

EditContextMenuItems.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default class GenericTrackListView extends PureComponent {
  static propTypes = {
    userIsPatron: PropTypes.bool.isRequired,
    playlist: Shapes.Playlist.isRequired,
    queueItem: PropTypes.object,
    isOnlyRadioAvailable: PropTypes.bool,

    imageAnnotation: PropTypes.object,
    pathname: PropTypes.string.isRequired,
    messages: PropTypes.object.isRequired,

    hideDownloadButton: PropTypes.bool,
    showAllTracksDuration: PropTypes.bool,
    entityType: PropTypes.oneOf(['album', 'playlist', 'personal_playlist', ENTITY_TYPE_MIX])
      .isRequired,

    ...shareableEntityPropTypes,
    ...playlistableEntityPropTypes,
    ...editableEntityPropTypes,
    ...collectibleEntityPropTypes,
    ...queueOriginComponentPropTypes,

    intl: intlShape,
    track: PropTypes.func,

    bookletUrl: PropTypes.string,
  };

  static defaultProps = {
    isEditable: false,
    showAllTracksDuration: true,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      expandedTracks: {},
    };
    this._cache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: __CAPACITOR__ ? 70 : 52,
      defaultHeight: __CAPACITOR__ ? 70 : 52,
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.playlist.id !== nextProps.playlist.id) {
      // In case one playlist has items with different item than the other
      this._cache.clearAll();
    }
    if (nextProps.location.hash === '#long-description') {
      this.props.track('Viewed Playlist Long Description', { playlistID: nextProps.playlist.id });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isEditable) {
      return;
    }
    const oldTracks = prevProps.playlist.tracks.map(t => t.id).join('');
    const newTracks = this.props.playlist.tracks.map(t => t.id).join('');
    if (oldTracks !== newTracks) {
      // Necessary for personal playlists if an expanded item changes position
      this._cache.clearAll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onClickTrack = (trackId, index, playing) => {
    this.props.playFromTrack(trackId, index, playing);
  };

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const trackIds = this.props.trackIds;
    const from = result.source.index;
    const to = result.destination.index;
    const updatedTrackIds = reorder(trackIds, from, to);
    const action = { from, to, trackId: trackIds[from] };
    this.props.onReorder(updatedTrackIds, action);
  };

  getShareButtonText() {
    const { intl, pathname } = this.props;
    const isAlbum = pathname.includes('album');
    if (isAlbum) {
      return intl.formatMessage(messages.shareAlbum);
    }
    return intl.formatMessage(messages.sharePlaylist);
  }

  renderInnerTrackItem = ({ track, index, dragHandleProps, measure }) => {
    const { playlist, isEditable } = this.props;
    const { onDeleteTrack, onAddTrackToPlaylistClick } = this.props;
    const { isQueuedTrack, isPlayingTrack } = this.props;
    const isCurrentTrack = isQueuedTrack(track.id, index);
    const isPlaying = isPlayingTrack(track.id, index);

    return (
      <PlaylistItem
        indexInPlaylist={index}
        playlist={playlist}
        track={track}
        isCurrentTrack={isCurrentTrack}
        playing={isPlaying}
        onClick={this.onClickTrack}
        onAddToPlaylistButtonClick={onAddTrackToPlaylistClick}
        isDuplicatePiece={isDuplicatePiece(playlist.tracks, track.piece.id)}
        isInPersonalPlaylist={isEditable}
        removeFromPlaylist={onDeleteTrack}
        dragHandleProps={dragHandleProps}
        entityType={this.props.entityType}
        isExpanded={Boolean(this.state.expandedTracks[track.id])}
        measure={measure}
        toggleExpanded={this.toggleExpanded}
      />
    );
  };

  renderTrackItem = ({ index, parent, style }) => {
    const track = this.props.playlist.tracks[index];
    const key = `${track.id}_${index}`;
    return (
      <CellMeasurer cache={this._cache} columnIndex={0} key={key} rowIndex={index} parent={parent}>
        {({ measure }) => (
          <div role="listitem" style={style} className={styles.listItem}>
            {this.renderInnerTrackItem({ track, index, measure })}
          </div>
        )}
      </CellMeasurer>
    );
  };

  renderDraggableTrackItem = ({ index, parent, style }) => {
    const track = this.props.playlist.tracks[index];
    const key = `${track.id}_${index}`;
    return (
      <Draggable key={key} draggableId={key} index={index}>
        {(provided, snapshot) => (
          <CellMeasurer
            cache={this._cache}
            columnIndex={0}
            key={key}
            rowIndex={index}
            parent={parent}
          >
            {({ measure }) => (
              <div
                role="listitem"
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={classnames(styles.draggableItem, styles.listItem, {
                  [styles.isDragging]: snapshot.isDragging,
                })}
                style={{
                  ...style,
                  ...provided.draggableProps.style,
                }}
              >
                {this.renderInnerTrackItem({
                  track,
                  index,
                  dragHandleProps: provided.dragHandleProps,
                  measure,
                })}
              </div>
            )}
          </CellMeasurer>
        )}
      </Draggable>
    );
  };

  renderInnerList = (renderFunction, ref, width) => {
    // We need to virtualize the list rendering, since personal playlists can have
    // hundreds of tracks which renders them unusable in capacitor mode.
    // Virtualizing the lists is complicated by some circumstances:

    // 1. The list items have a dynamic height. Solved by using <CellMeasurer>.
    //    Note that we need to call the .measure() method manually when an items is
    //    expanded/collapsed so the heights are correctly updated.
    // 2. The scrolling element is the window element in capacitor, but the
    //    .content element in other modes. Solved by using the <WindowScroller>
    //    component with a dynamic scrollingElement.
    // 3. Items must be draggable in personal playlist mode. react-beatiful-dnd
    //    plays well with the react-virtualized library in mode="virtual". See this
    //    example: https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/virtual/react-virtualized/window-list.jsx
    //    However, there is one known issue that was introduced in the version
    //    12.0.0-beta.11: https://github.com/atlassian/react-beautiful-dnd/issues/2111
    //    which forces us to pin the react-beatiful-dnd dependency to
    //    12.0.0-beta.10 for the time being.
    const tracks = this.props.playlist.tracks;
    const scrollingElement =
      typeof window === 'undefined' || __CAPACITOR__
        ? undefined
        : document.querySelector('.content');
    return (
      // Key needs to be passed as a workaround for a known issue https://github.com/bvaughn/react-virtualized/issues/1256
      <WindowScroller scrollElement={scrollingElement} key={scrollingElement}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <div
            ref={listRef => {
              registerChild(listRef);
              this.listRef = listRef;
            }}
            data-test="list.container"
            data-test-length={tracks.length}
          >
            <VirtualizedList
              // VirtualizedList is memoized and does a shallow prop
              // comparison, this forces it to re-render after resorting
              // the tracks
              sortKey={this.props.tracks.map(track => track.id).join('')}
              rowCount={tracks.length}
              autoHeight
              height={height}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              overscanRowCount={10}
              rowHeight={this._cache.rowHeight}
              width={width}
              ref={ref}
              rowRenderer={renderFunction}
              style={{ overflow: __CAPACITOR__ ? undefined : 'visible' }}
              containerRole="list"
              role="none"
            />
          </div>
        )}
      </WindowScroller>
    );
  };

  renderList = () => {
    return (
      <AutoSizer disableHeight>
        {({ width }) => this.renderInnerList(this.renderTrackItem, undefined, width)}
      </AutoSizer>
    );
  };

  renderDraggableList = () => {
    return (
      <AutoSizer disableHeight>
        {({ width }) => (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable
              droppableId="droppable-pp"
              mode="virtual"
              getContainerForClone={() => document.getElementById('app-content')}
              renderClone={(provided, snapshot, rubric) => (
                <div
                  role="listitem"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  className={classnames(styles.draggableItem, styles.listItem, {
                    [styles.isDragging]: snapshot.isDragging,
                  })}
                >
                  {this.renderInnerTrackItem({
                    track: this.props.playlist.tracks[rubric.source.index],
                    index: rubric.source.index,
                    dragHandleProps: provided.dragHandleProps,
                  })}
                </div>
              )}
            >
              {({ innerRef }) =>
                this.renderInnerList(
                  this.renderDraggableTrackItem,
                  () => {
                    // react-virtualized has no way to get the VirtualizedList DOM node
                    // We need to get to it via the parent ref
                    if (this.listRef && this.listRef.children.length > 0) {
                      innerRef(this.listRef.children[0]);
                    }
                  },
                  width
                )
              }
            </Droppable>
          </DragDropContext>
        )}
      </AutoSizer>
    );
  };

  renderTrackList = () => {
    const { isEditable } = this.props;
    return isEditable ? this.renderDraggableList() : this.renderList();
  };

  renderPlaylistHeader() {
    const { intl } = this.props;
    const { playlist, isPlaying, isCollectible, isInCollection, bookletUrl, entityType } =
      this.props;
    const { description, longDescription, title, imageUrl, tracks } = playlist;
    const { togglePlayAll, toggleIsInCollection } = this.props;
    const { showShareModal, onAddToPlaylistClick } = this.props;
    const { hideDownloadButton } = this.props;
    const { isEditable, onEdit, onDelete } = this.props;
    const { showAllTracksDuration } = this.props;
    const playlistDuration = sum(tracks.map(track => track.duration));

    const pausedTitle = this.props.isOnlyRadioAvailable
      ? intl.formatMessage(radioButtonMessages.playAllButtonPausedText)
      : intl.formatMessage(this.props.messages.playAllButtonPausedText);
    const playingTitle = this.props.isOnlyRadioAvailable
      ? intl.formatMessage(radioButtonMessages.playAllButtonPlayingText)
      : intl.formatMessage(this.props.messages.playAllButtonPlayingText);

    return (
      <React.Fragment>
        {__CAPACITOR__ && (
          <CapacitorHeaderBar
            title={this.props.playlist.title}
            onlyShowTitleOnScroll
            contentOverflow
          />
        )}
        <div className={styles.header}>
          <figure className={styles.figure}>
            <Image
              noBase
              src={imageUrl}
              className={styles.headerImage}
              width={720}
              height={720}
              alt=""
            />
            {bookletUrl && (
              <BookletButton
                bookletUrl={bookletUrl}
                trackOpenBooklet={this.trackOpenBooklet}
                className={styles.bookletBtn}
                imageUrl={imageUrl}
                entityType={entityType}
              />
            )}
          </figure>
          <div className={styles.headerInfo}>
            <h1 className={styles.headerTitle}>{title}</h1>
            <div className={styles.headerBtns}>
              <PlayButton
                pausedTitle={pausedTitle}
                playingTitle={playingTitle}
                onClick={togglePlayAll}
                playing={isPlaying}
                moduleVariant="header"
                labelVisible
                size="small"
                data-test="generic-track-list-view.play-btn"
                isRadio={this.props.isOnlyRadioAvailable}
              />
              {bookletUrl && (
                <BookletButton
                  bookletUrl={bookletUrl}
                  trackOpenBooklet={this.trackOpenBooklet}
                  hideText={false}
                  className={styles.bookletBtn}
                  imageUrl={imageUrl}
                  entityType={entityType}
                />
              )}
              <div className={styles.actionBtns}>
                {isCollectible && (
                  <CollectionButton active={isInCollection} onClick={toggleIsInCollection} />
                )}
                {__CAPACITOR__ && !hideDownloadButton && (
                  <CapacitorDownloadButton
                    entityType={this.props.entityType}
                    entityIdOrSlug={this.props.playlist.id}
                  />
                )}
                <Feature id="user_playlists">
                  {allowed => {
                    const showAddToPlaylistButton = onAddToPlaylistClick && tracks.length > 0;
                    const showShareButton = showShareModal;
                    const showEditableButtons = allowed && isEditable;
                    const shareButtonText = this.getShareButtonText();

                    return !__CAPACITOR__ ? (
                      <React.Fragment>
                        {showAddToPlaylistButton && (
                          <AddToPlaylistButton onClick={onAddToPlaylistClick} />
                        )}
                        {showShareButton && (
                          <ShareButton onClick={showShareModal} text={shareButtonText} />
                        )}
                        {showEditableButtons && (
                          <ContextMenu data-test="generic-track-list.context-menu">
                            <EditContextMenuItems onEdit={onEdit} onDelete={onDelete} />
                          </ContextMenu>
                        )}
                      </React.Fragment>
                    ) : (
                      (showEditableButtons || showAddToPlaylistButton || showShareButton) && (
                        <ContextMenu data-test="generic-track-list.context-menu">
                          {showAddToPlaylistButton && (
                            <AddToPlaylistContextMenuItem onClick={onAddToPlaylistClick} />
                          )}
                          {showShareButton && (
                            <ShareContextMenuItem onClick={showShareModal} text={shareButtonText} />
                          )}
                          {showEditableButtons && (
                            <EditContextMenuItems onEdit={onEdit} onDelete={onDelete} />
                          )}
                        </ContextMenu>
                      )
                    );
                  }}
                </Feature>
              </div>
            </div>
            {description && <p className={styles.description}>{description}</p>}
            {longDescription && (
              <Link onlyActiveOnIndex to={`${this.props.pathname}#long-description`}>
                <FormattedMessage id="playlist.description.read-more" defaultMessage="Read more…" />
              </Link>
            )}
            {showAllTracksDuration && (
              <div>
                {playlist.publishDate && (
                  <React.Fragment>
                    <FormattedDate
                      value={new Date(playlist.publishDate)}
                      day="2-digit"
                      month="2-digit"
                      year="numeric"
                    />
                    &nbsp;•&nbsp;
                  </React.Fragment>
                )}
                <PlaylistTrackCount trackCount={playlist.tracks.length} />
                &nbsp;•&nbsp;
                <FormattedTime>{playlistDuration}</FormattedTime>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderLongDescriptionParts = longDescriptionParts =>
    longDescriptionParts.map((part, i) => (part ? <p key={i}>{part}</p> : <br key={i} />));

  renderLongDescription = () => {
    const { longDescription } = this.props.playlist;
    let longDescriptionParts;
    if (longDescription) {
      longDescriptionParts = longDescription.split(/\r\n|\r|\n/g);
    }

    if (!longDescription) {
      return null;
    }

    return (
      <div className={styles.descriptionLong} id="long-description">
        {this.renderLongDescriptionParts(longDescriptionParts)}
      </div>
    );
  };

  renderCredits() {
    const { imageAnnotation, playlist } = this.props;
    if (!imageAnnotation && !playlist.copyright) {
      return null;
    }

    return (
      <div className={styles.credits}>
        {imageAnnotation && <ImageCredit annotation={imageAnnotation} />}
        {playlist.copyright && <div>©℗ {playlist.copyright}</div>}
      </div>
    );
  }

  renderPlaylistBody() {
    return (
      <div
        className={styles.body}
        data-test="generic-track-list.body"
        data-test-length={this.props.playlist.tracks.length}
      >
        {this.renderTrackList()}
        {this.renderLongDescription()}
        {this.renderCredits()}
      </div>
    );
  }

  render() {
    const { pathname } = this.props;
    const stylesView = classnames(styles.view);

    const trackListType = ['album', 'playlist'].find(a => pathname.includes(a));

    return (
      <div className={stylesView} data-test={trackListType ? trackListType + '-page' : null}>
        <CapacitorSkeletonIfLoading withContainer>
          {this.renderPlaylistHeader()}
          {this.renderPlaylistBody()}
        </CapacitorSkeletonIfLoading>
      </div>
    );
  }

  trackOpenBooklet = () => {
    this.props.track('Opened Booklet', { albumID: this.props.playlist.id });
  };

  toggleExpanded = (id, measure) => {
    this.setState(
      {
        expandedTracks: {
          ...this.state.expandedTracks,
          [id]: !this.state.expandedTracks[id],
        },
      },
      measure
    );
  };

  handleResize = debounce(() => {
    this._cache.clearAll();
  }, 500);
}
