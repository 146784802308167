import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as analyticsActions from '../../../actions/analytics';

import InstallButton from './InstallButton';
import NotifyButton from './NotifyButton';

class CtaButton extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isGeoBlocked: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    trackingData: PropTypes.object,
    onClick: PropTypes.func,
    analyticsTrack: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isGeoBlocked: false,
    isMobile: false,
    trackingData: {},
  };

  onButtonClick = () => {
    this.props.analyticsTrack('Clicked CTA Button', this.props.trackingData);
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const { isGeoBlocked, isMobile, children, ...buttonProps } = this.props;
    return isGeoBlocked ? (
      <NotifyButton {...buttonProps} onClick={this.onButtonClick} />
    ) : (
      <InstallButton {...buttonProps} onClick={this.onButtonClick} isMobile={isMobile}>
        {children}
      </InstallButton>
    );
  }
}

export default connect(null, { analyticsTrack: analyticsActions.track })(CtaButton);
